import React, { useEffect, useState, useContext } from "react";
import { useQuery } from "@hooks";
import {
	Main,
	Grid,
	Flex,
	Col,
	Input,
	Button,
	Center,
	Svg,
	Text,
} from "@components";
import { Layout } from "@layout";
import { Form } from "@containers/Login";
import AdminContext from "@context";
import { navigate } from "gatsby";

import {
	Login,
	Logout,
	createUUID,
	FingerprintJSX,
	getCatalogs,
	activarCuenta,
} from "@services";
import { REGEX } from "@constants";

import logotipo from "@assets/logo.svg";

const LoginPage = () => {
	const query = useQuery();
	const { emailRgx } = REGEX;
	const { auth, setAuth, setCatalogos, clearState } = useContext(AdminContext);

	const [error, setError] = useState("");
	const [errorQuery, setErrorQuery] = useState("");
	const [successQuery, setSuccessQuery] = useState("");
	const [requesting, setRequesting] = useState(false);
	const [enableButton, setEnableButton] = useState(false);
	const [fingerprint, setFingerprint] = useState(null);
	const [activationToken, setActivationToken] = useState(null);
	const [data, setData] = useState({
		email: "",
		password: "",
	});

	useEffect(() => {
		clearState();
		const expireToken = query.get("expiredToken");
		const expireSession = query.get("expiredSession");
		const activation_token = query.get("on");

		(!!expireToken || !!expireSession) &&
			setErrorQuery("Por su seguridad la sesión ha expirado");

		activation_token && setActivationToken(activation_token);

		FingerprintJSX.then(({ get }) => get())
			.then(({ visitorId }) => setFingerprint(visitorId))
			.catch(() => setFingerprint(createUUID()));
	}, []);

	useEffect(() => {
		if (activationToken) {
			activarCuenta(activationToken)
				.then((email) => {
					handleChangeData("email", email);
					setSuccessQuery("Tu cuenta ha sido activada exitosamente");
					setActivationToken(null);

					setTimeout(() => {
						setSuccessQuery("");
					}, 5000);
				})
				.catch(({ error }) => {
					setActivationToken(null);
					setErrorQuery(error);
				});
		}
	}, [activationToken]);

	useEffect(() => {
		setAuth({
			...auth,
			uuid: fingerprint,
		});
	}, [fingerprint]);

	const handlerLogin = () => {
		setRequesting(true);
		Login({
			email: btoa(btoa(data.email)),
			password: btoa(btoa(data.password)),
			uuid: fingerprint,
		})
			.then(({ token, username, email, role, uuid, unique_code }) => {
				setAuth({
					...auth,
					isAuthenticated: true,
					unique_code,
					username,
					token,
					email,
					uuid,
					role,
				});

				navigate("/");
			})
			.catch(({ error }) => setError(error))
			.finally(() => setRequesting(false));
	};

	const handleChangeData = (type, value) => {
		errorQuery && setErrorQuery("");
		error && setError("");
		setData({
			...data,
			[type]: value,
		});
	};

	useEffect(() => {
		setEnableButton(emailRgx.test(data.email) && data.password.length > 8);
	}, [data]);

	return (
		<Main theme="light">
			<Grid className="pv:2">
				<Flex className="mt:2" justify="center" align="center">
					<Center>
						<Svg icon={logotipo} width={60} height={60} />
					</Center>
					<Col xs={18} md={8} className="p:1">
						<Form successQuery={successQuery} errorQuery={errorQuery}>
							<Flex>
								<Col xs={20} className="mb:1">
									<Input
										value={data.email}
										type="email"
										getValue={(v) => handleChangeData("email", v)}
										placeholder="Email"
									/>
								</Col>
								<Col xs={20}>
									<Input
										value={data.password}
										type="password"
										getValue={(v) => handleChangeData("password", v)}
										placeholder="Contraseña"
									/>
								</Col>
								<Col xs={20} className="pv:05">
									<Text fs={10} color="#ff0050">
										{error}
									</Text>
								</Col>
							</Flex>
							<Flex justify="end" className="mt:1">
								<Button
									disabled={!enableButton && !requesting}
									loading={requesting}
									onClick={() => handlerLogin()}
									square
									primary
								>
									Iniciar sesión
								</Button>
							</Flex>
						</Form>
					</Col>
					<Col xs={20}>
						<small className="t:center mt:2 opacity:5">
							Digital Booting &copy; 2022 - CRM Digital Booting v1.0.1 Beta
						</small>
					</Col>
				</Flex>
			</Grid>
		</Main>
	);
};

export default LoginPage;
