import React from 'react'
import st from 'styled-components'
import { Text } from "@components"

const Form = st.div`
	background-color: white;
	box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.1);
	color: #232525;
	border-radius: 10px;
	padding: 2rem;
`

const ErrorQuery = st.div`
	background-color: rgba(255, 0, 0, 0.1);
	border: 1px solid rgba(255, 0, 0, 0.1);
	color: red;
	border-radius: 5px;
	margin-bottom: 1rem;
	font-size: 12px;
	padding: 0.5rem 1rem;
	text-align: center;
`

const SuccessQuery = st.div`
	background-color: rgba(0, 255, 0, 0.1);
	border: 1px solid rgba(0, 255, 0, 0.1);
	color: green;
	border-radius: 5px;
	margin-bottom: 1rem;
	font-size: 12px;
	padding: 0.5rem 1rem;
	text-align: center;
`

export default ({children, errorQuery, successQuery}) => {
	return (
		<Form>
			<Text fw={700} fs={20} align="center" className="pb:15">Digital Booting</Text>
			{
				errorQuery && (
					<ErrorQuery>{errorQuery}</ErrorQuery>
				)
			}
			{
				successQuery && (
					<SuccessQuery>{successQuery}</SuccessQuery>
				)
			}
			{children}
		</Form>
	)
}
